// Required CSS import, unless you're overriding the styling
import '@knocklabs/react/dist/index.css'

import { KnockFeedProvider, KnockProvider } from '@knocklabs/react'
import React from 'react'
import ReactDOM from 'react-dom'

import { NotificationToaster } from '@/src/components/Notifications/Toaster'

let KNOCK_API_KEY = 'pk_test_tE0wN0LLiK_ScEuWyJ2Dy478rK4wVnZ1KA9e07o9Zlo'
if (window.location.host === 'app.resemble.ai') {
  KNOCK_API_KEY = 'pk_zVJGKOmKHFJG5pHCqQzkLkUi3-qQqk3kb671cvO5cCM'
}

const CHANNEL_ID = 'b19e1936-aa83-4a82-bb03-801e3a2fdf56'

const NotificationToastProducer = () => {
  return (
    <KnockProvider apiKey={KNOCK_API_KEY} userId={window.CURRENT_USER_ID || '-1'}>
      <KnockFeedProvider feedId={CHANNEL_ID}>
        <NotificationToaster />
      </KnockFeedProvider>
    </KnockProvider>
  )
}

declare global {
  interface Window {
    mountToasts: () => void
  }
}

const mountToasts = () =>
  window.mountReactWhenReady(
    '#toasts',
    (node) => {
      ReactDOM.render(<NotificationToastProducer />, node)
    },
    false,
  )

window.mountToasts = mountToasts
mountToasts()
