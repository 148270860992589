import { useKnockFeed } from '@knocklabs/react'
import React, { useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'

interface KnockBlock {
  rendered: string
}

interface KnockNotification {
  id: string
  blocks: KnockBlock[]
}

export const NotificationToaster = () => {
  const { feedClient } = useKnockFeed()

  // Unstructured JSON from API
  const onNotificationsReceived = ({ items }: any) => {
    // Whenever we receive a new notification from our real-time stream, show a toast
    // (note here that we can receive > 1 items in a batch)
    items.forEach((notification: KnockNotification) => {
      toast.custom(<div className='tw-rounded tw-bg-white tw-px-4 tw-py-4 tw-shadow-md' dangerouslySetInnerHTML={{ __html: notification.blocks[0].rendered }}></div>, {
        id: notification.id,
        position: 'bottom-right',
      })
    })

    // Optionally, you may want to mark them as "seen" as well
    // feedClient.markAsSeen(items);
  }

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on('items.received.realtime', onNotificationsReceived)

    // Cleanup
    return () => feedClient.off('items.received.realtime', onNotificationsReceived)
  }, [feedClient])

  return (
    <div>
      <Toaster />
    </div>
  )
}
